/*
 * @Description: ali-oss
 * @Author: zhangguoliang
 * @Date: 2021-08-19 10:09:03
 * @LastEditors: renmingming
 * @LastEditTime: 2022-06-15 08:59:28
 */

import OSS, { Options } from 'ali-oss';

class AliOSS {
  options: Options = {
    accessKeyId: process.env.VUE_APP_ALI_OSS_KEY,
    accessKeySecret: process.env.VUE_APP_ALI_OSS_SECRET,
    bucket: process.env.VUE_APP_ALI_OSS_BUCKET,
    secure: true,
    region: process.env.VUE_APP_ALI_OSS_REGION,
  };
  client = new OSS(this.options);
  getDate(): string {
    const date = new Date();
    return (
      date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate()
    );
  }
  getRandomStr(): string {
    return Math.random().toString(36).slice(2);
  }
  async upload(file: File | null, path = 'import'): Promise<string> {
    if (!file) return '';
    const name = file.name.split('.')[1];
    let saveAs = '';
    if (['bmp', 'jpg', 'png', 'gif', 'webp', 'jpeg'].includes(name)) {
      saveAs = `image/${this.getDate()}/${this.getRandomStr()}-${file.name}`;
    } else {
      saveAs = `${path}/${file.name}`;
    }
    const result: any = await this.client.multipartUpload(saveAs, file, {});
    return this.getDownloadUrl(result);
  }
  getDownloadUrl(result: any): string {
    const uploadFile = result.name;
    // 获取文件url
    const url = this.client.signatureUrl(uploadFile, {
      expires: 3600 * 1000 * 24 * 365 * 100,
      response: {
        'content-disposition': `attachment; filename=${encodeURIComponent(
          uploadFile
        )}`,
      },
    });
    return url.split('?')[0];
  }
  download(name: string, projectName: string, type: string): void {
    projectName = projectName + '.' + type;
    const url = this.client.signatureUrl(name, {
      expires: 3600,
      response: {
        'content-disposition': `attachment; filename=${projectName}`,
      },
    });
    window.location.href = url;
  }
}

export default AliOSS;
